import { useState, useEffect } from 'react';
import {
	Container,
	Card,
	SimpleGrid,
	Text,
	Flex,
	Space,
	Button,
	Group,
	Loader,
} from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import dayjs from 'dayjs';
import Styles from '../../assets/styles/pages/home.module.css';
import { useBooking } from '../../hooks/use-booking';

interface CalendarCardProps {
	nextStep: (step?: number) => void;
	previousStep: (step?: number) => void;
}

interface AvailableTime {
	starttime: string;
	endtime: string;
	doctor: string;
}

export default function CalendarCard({
	nextStep,
	previousStep,
}: CalendarCardProps) {
	const { booking, actions } = useBooking();

	// Initialize month and year based on the first available date
	const firstAvailableDate = booking.consultationType.availableTimes.length
		? dayjs(booking.consultationType.availableTimes[0].startTime)
		: dayjs();
	const [currentMonth, setCurrentMonth] = useState(firstAvailableDate.month());
	const [currentYear, setCurrentYear] = useState(firstAvailableDate.year());
	const [selectedDate, setSelectedDate] = useState<number | null>(null);
	const [selectedTime, setSelectedTime] = useState<AvailableTime | null>(null);
	const [availableTimes, setAvailableTimes] = useState<AvailableTime[]>([]);
	const [availableDays, setAvailableDays] = useState<number[]>([]);
	const [loading, setLoading] = useState(false);

	const groupTimesByDate = () => {
		const timesByDate: Record<string, AvailableTime[]> = {};

		booking.consultationType.availableTimes.forEach((time: any) => {
			const dateKey = dayjs(time.startTime).format('YYYY-MM-DD');
			if (!timesByDate[dateKey]) timesByDate[dateKey] = [];

			timesByDate[dateKey].push({
				starttime: time.startTime,
				endtime: time.endTime,
				doctor: time.doctor,
			});
		});

		const availableDates = Object.keys(timesByDate)
			.map((date) => dayjs(date))
			.filter(
				(date) => date.month() === currentMonth && date.year() === currentYear
			)
			.map((date) => date.date());

		setAvailableDays(availableDates);
		return timesByDate;
	};

	useEffect(() => {
		const updateAvailableTimes = () => {
			setLoading(true);
			const timesByDate = groupTimesByDate();

			// Flatten timesByDate to check if any times are available in the month
			const flattenedTimes = Object.values(timesByDate).flat();
			if (flattenedTimes.length === 0) {
				setAvailableTimes([]);
				setSelectedDate(null);
			} else {
				setAvailableTimes(flattenedTimes);
			}

			setSelectedTime(null);
			setLoading(false);
		};

		updateAvailableTimes();
	}, [currentMonth, currentYear, booking.consultationType.availableTimes]);

	const getDaysInMonth = (month: number, year: number) =>
		dayjs(`${year}-${month + 1}-01`).daysInMonth();
	const getFirstDayOfMonth = (month: number, year: number) =>
		dayjs(`${year}-${month + 1}-01`).day();

	const handleMonthChange = (direction: 'prev' | 'next') => {
		if (direction === 'prev') {
			if (currentMonth === 0) {
				setCurrentMonth(11);
				setCurrentYear((prevYear) => prevYear - 1);
			} else {
				setCurrentMonth((prevMonth) => prevMonth - 1);
			}
		} else {
			if (currentMonth === 11) {
				setCurrentMonth(0);
				setCurrentYear((prevYear) => prevYear + 1);
			} else {
				setCurrentMonth((prevMonth) => prevMonth + 1);
			}
		}
		resetSelection();
	};

	const resetSelection = () => {
		setSelectedDate(null);
		setSelectedTime(null);
		setAvailableTimes([]);
	};

	const handleDateSelect = (date: number) => {
		if (!availableDays.includes(date)) return;

		setSelectedDate(date);
		const selectedDay = dayjs(`${currentYear}-${currentMonth + 1}-${date}`);
		const timesForDate = booking.consultationType.availableTimes
			.filter((time: any) => dayjs(time.startTime).isSame(selectedDay, 'day'))
			.map((time: any) => ({
				starttime: time.startTime,
				endtime: time.endTime,
				doctor: time.doctor,
			}));

		setAvailableTimes(timesForDate);
	};

	const handleNext = () => {
		if (!selectedDate || !selectedTime) return;

		const selectedDayISO = dayjs(
			`${currentYear}-${currentMonth + 1}-${selectedDate}`
		).toISOString();
		const starttimeISO = dayjs(selectedTime.starttime).toISOString();
		const endtimeISO = dayjs(selectedTime.endtime).toISOString();

		actions.handleAddCalendar({
			selectedDate: selectedDayISO,
			starttime: starttimeISO,
			endtime: endtimeISO,
			email: selectedTime.doctor,
		});

		nextStep(2);
	};

	const daysInMonth = getDaysInMonth(currentMonth, currentYear);
	const firstDayOfMonth = getFirstDayOfMonth(currentMonth, currentYear);
	const datesArray = Array.from(
		{ length: firstDayOfMonth + daysInMonth },
		(_, i) => (i < firstDayOfMonth ? null : i - firstDayOfMonth + 1)
	);

	return (
		<Container>
			<Space h="md" />
			<Card shadow="sm" padding="lg" radius="md" withBorder>
				<Flex justify="space-between" align="center" mb="md">
					<IconChevronLeft
						onClick={() => handleMonthChange('prev')}
						style={{ cursor: 'pointer' }}
					/>
					<Text size="lg" weight={500}>
						{dayjs().month(currentMonth).format('MMMM')} {currentYear}
					</Text>
					<IconChevronRight
						onClick={() => handleMonthChange('next')}
						style={{ cursor: 'pointer' }}
					/>
				</Flex>

				<SimpleGrid cols={7} spacing="xs">
					{['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
						<Text align="center" key={day} weight={500}>
							{day}
						</Text>
					))}
				</SimpleGrid>

				<SimpleGrid cols={7} spacing="xs">
					{datesArray.map((date, index) => (
						<Card
							key={index}
							padding="sm"
							className={Styles.calendarDate}
							withBorder
							radius="sm"
							align="center"
							onClick={() => date && handleDateSelect(date)}
							style={{
								backgroundColor: availableDays.includes(date as number)
									? selectedDate === date
										? '#d3f9d8'
										: 'white'
									: '#f0f0f0',
								cursor: availableDays.includes(date as number)
									? 'pointer'
									: 'not-allowed',
								opacity: availableDays.includes(date as number) ? 1 : 0.5,
							}}
						>
							{date && <Text>{date}</Text>}
						</Card>
					))}
				</SimpleGrid>

				<Space h="md" />

				{/* Display available times for the selected date */}
				{selectedDate && availableTimes.length > 0 && (
					<>
						<Text align="center" weight={500} mb="xs">
							Available times for {selectedDate}{' '}
							{dayjs().month(currentMonth).format('MMMM')} {currentYear}
						</Text>
						<Group position="center">
							{availableTimes.map((time, index) => (
								<Button
									key={index}
									onClick={() => setSelectedTime(time)}
									variant={
										selectedTime?.starttime === time.starttime
											? 'filled'
											: 'outline'
									}
								>
									{dayjs(time.starttime).format('HH:mm')} -{' '}
									{dayjs(time.endtime).format('HH:mm')}
								</Button>
							))}
						</Group>
					</>
				)}

				{/* No available times message and back button */}
				{availableTimes.length === 0 && !loading && (
					<Flex direction="column" align="center" mt="lg">
						<Text align="center" color="dimmed" mb="md">
							No doctor is available at the moment for this product.
						</Text>
						<Button variant="outline" onClick={() => previousStep()}>
							Go Back
						</Button>
					</Flex>
				)}

				{loading && (
					<Flex justify="center" align="center">
						<Loader size="md" />
					</Flex>
				)}

				{selectedDate && selectedTime && (
					<Group position="center" mt="lg">
						<Button onClick={handleNext}>Next</Button>
					</Group>
				)}
			</Card>
		</Container>
	);
}
