import { Card, Text, Badge, Button, Group, createStyles } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { AvailableRoutes, getPath } from '../../pages/navigation';

const useStyles = createStyles((theme) => ({
	card: {
		position: 'relative',
		cursor: 'pointer',
		overflow: 'hidden',
		transition: 'transform 200ms ease, box-shadow 100ms ease',
		padding: theme.spacing.xl,

		'&:hover': {
			boxShadow: theme.shadows.md,
			transform: 'scale(1.02)',
		},
	},
	badge: {
		textTransform: 'uppercase',
		fontWeight: 500,
	},
}));

export default function ConsultationCard({
	title,
	description,
	path,
	onClick,
	badgeLabel,
}: {
	title: string;
	description: string;
	badgeLabel?: string;
	path?: AvailableRoutes;
	onClick?: () => void;
}) {
	const navigate = useNavigate();
	const { classes } = useStyles();

	const handleClick = () => {
		if (!(path || onClick)) {
			return;
		}
		if (path) {
			navigate(getPath(path));
		}
		if (onClick) {
			onClick();
		}
	};

	return (
		<Card
			shadow="sm"
			padding="lg"
			radius="md"
			withBorder
			className={classes.card}
			onClick={handleClick}
		>
			<Card.Section></Card.Section>

			<Group position="apart" mt="md" mb="xs">
				<Text weight={500}>{title}</Text>
				{badgeLabel && (
					<Badge className={classes.badge} color="gray" variant="light">
						{badgeLabel}
					</Badge>
				)}
			</Group>

			<Text size="sm" color="dimmed">
				{description}
			</Text>

			<Button color="teal" fullWidth mt="md" radius="md">
				Book Consultation Now
			</Button>
		</Card>
	);
}
