import { Box, Grid, SimpleGrid, Space, Text, Anchor } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import Styles from '../../assets/styles/pages/home.module.css';
import NavCard from '../../components/core/nav-card';
import Container from '../layout/container';
import { useBooking } from '../../hooks/use-booking';
import mantineConfig from '../../assets/styles/config/mantine.config.json';

const ConsultationNav = ({
	nextStep,
}: {
	nextStep: (step?: number) => void;
}) => {
	const { actions } = useBooking();

	const handleNext = async (bookingFor: 'patient' | 'child') => {
		actions.handleAddFor(bookingFor);
		nextStep();
	};

	const { t } = useTranslation(['default']);

	return (
		<Container>
			<Space h="md" />
			<Grid className={Styles['flex-container-one']}>
				<SimpleGrid
					cols={2}
					spacing="xl"
					breakpoints={[{ maxWidth: 625, cols: 1, spacing: 'md' }]}
				>
					<NavCard
						imageName="consultation"
						title={t('tr.book-for-me')}
						onClick={() => handleNext('patient')}
					/>
					<Box>
						<NavCard
							imageName="consultation_child"
							title={t('tr.book-for-child')}
							onClick={() => handleNext('child')}
						/>
					</Box>
				</SimpleGrid>
			</Grid>
			<Space h="xl" />
			<Text
				color="{mantineConfig.mantine.text.color}"
				weight={mantineConfig.mantine.text.href.fontWeight}
				style={{
					fontFamily: mantineConfig.mantine.global.fontFamily,
					fontSize: mantineConfig.mantine.text.href.fontSize,
				}}
			>
				{' '}
				<Anchor
					color={mantineConfig.mantine.text.href.color}
					style={{
						fontFamily: mantineConfig.mantine.global.fontFamily,
						fontSize: mantineConfig.mantine.text.href.fontSize,
					}}
					weight={mantineConfig.mantine.text.href.fontWeight}
					href="/profile"
				>
					{t('tr.pathdesc')}{' '}
				</Anchor>
				{t('tr.infoText')}
			</Text>
		</Container>
	);
};

export default ConsultationNav;
