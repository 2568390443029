import Cookies from 'js-cookie';
import {
	ICancelConsultationResponse,
	IChildrenResponse,
	ICompleteProfileRequest,
	ICompleteProfileResponse,
	IConsultationResponse,
	ICreateChildRequest,
	ICreateChildResponse,
	ICreateConsultationRequest,
	ICreateConsultationResponse,
	ICreateFeedbackRequest,
	ICreateFeedbackResponse,
	ICreateInquiryRequest,
	ICreateInquiryResponse,
	IFollowUpResponse,
	IGetAvailabilityCalendar,
	IInvitation,
	ILabResultsResponse,
	IMedicalReport,
	IMessageResponse,
	INationalIdResponse,
	IPatientResponse,
	IPrescriptionResponse,
	IProfileResponse,
	IProviderResponse,
	IProvidersResponse,
	IReceiptResponse,
	ISummaryResponse,
	IUpdateChildRequest,
	IUpdateChildResponse,
	IUpdateChildStatusRequest,
	IUpdateFollowUpResponse,
	IUpdatePatientResponse,
	IUpdateVitalDetailsResponse,
	IVerifyPaymentRequest,
	IVerifyPaymentResponse,
	IVitalsResponse,
	IsPaid,
} from './types';

import { monoBaseApi, productApi, calendarApi } from './';

const userId = Cookies.get('PATIENT_userId');
const monoApi = monoBaseApi(); // mono api
const prodApi = productApi(); // product api
const calApi = calendarApi(); // calendar api

export const routes = {
	getProfile: async () => {
		const { data } = await monoApi.get<IProfileResponse>(
			`/api/v1/users/profile/${userId}`
		);
		return data;
	},
	getProvider: async () => {
		const { data } = await monoApi.get<IProviderResponse>(
			'/api/v1/patients/provider/information/1'
		);
		return data;
	},
	getProviders: async () => {
		const { data } = await monoApi.get<IProvidersResponse[]>(
			'/api/v1/providers/headers'
		);
		return data;
	},
	getNationalId: async (nationalId: string) => {
		const { data } = await monoApi.get<INationalIdResponse>(
			`/api/v1/patients/is-national-id/${nationalId}`
		);
		return data;
	},
	getPatient: async () => {
		const { data } = await monoApi.get<IPatientResponse>(
			`/api/v1/users/profile/${userId}`
		);
		return data;
	},

	updatePatient: async (payload: IPatientResponse) => {
		const formData = new FormData();
		Object.keys(payload).forEach((key) => {
			if (payload[key] == null && key == 'ninCard') {
			} else {
				formData.append(key, payload[key]);
			}
		});

		const { data } = await monoApi.put<IUpdatePatientResponse>(
			`/api/v1/patients/profile/edit/${userId}`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		);
		return data;
	},
	updateLocal: async (payload: IPatientResponse) => {
		const { data } = await monoApi.put<IPatientResponse>(
			`/api/v1/patients/profile/edit/locale/${userId}`,
			payload
		);
		return data;
	},

	completeProfile: async (payload: ICompleteProfileRequest) => {
		const { data } = await monoApi.put<ICompleteProfileResponse>(
			`/api/v1/patients/profile/complete/${userId}`,
			payload
		);
		return data;
	},
	getChildren: async () => {
		const { data } = await monoApi.get<IChildrenResponse[]>(`/api/v1/child`);
		return data;
	},
	getActiveChildren: async () => {
		const { data } =
			await monoApi.get<IChildrenResponse[]>(`/api/v1/child/active`);
		return data;
	},
	getChild: async (childId?: number) => {
		const { data } = await monoApi.get<IChildrenResponse>(
			`/api/v1/child/${childId}`
		);
		return data;
	},
	createChild: async (payload: ICreateChildRequest) => {
		const formData = new FormData();

		Object.keys(payload).forEach((key) => {
			if (payload[key] == null && key == 'NINCard') {
			} else {
				formData.append(key, payload[key]);
			}
		});
		const { data } = await monoApi.post<ICreateChildResponse>(
			`/api/v1/child`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		);
		return data;
	},
	updateChildStatus: async (payload: IUpdateChildStatusRequest) => {
		const { data } = await monoApi.put<IUpdateChildResponse>(
			`/api/v1/child/status`,
			payload
		);
		return data;
	},
	updateChild: async (payload: IUpdateChildRequest) => {
		const { data } = await monoApi.put<IUpdateChildResponse>(
			`/api/v1/child`,
			payload
		);
		return data;
	},
	getConsultation: async (consultationId?: number) => {
		const { data } = await monoApi.get<IConsultationResponse>(
			`/api/v1/consultations/${consultationId}`
		);
		return data;
	},
	createConsultation: async (payload: ICreateConsultationRequest) => {
		const { data } = await monoApi.post<ICreateConsultationResponse>(
			'/api/v1/booking/create',
			payload
		);
		return data;
	},
	updateFollowUp: async (followupId: number, payload: { paid: boolean }) => {
		const { data } = await monoApi.put<IUpdateFollowUpResponse>(
			`/api/v1/follow-ups/update/${followupId}`,
			payload
		);
		return data;
	},
	cancelConsultation: async (consultationId?: number) => {
		const { data } = await monoApi.put<ICancelConsultationResponse>(
			`/api/v1/consultations/cancel/${consultationId}`
		);
		return data;
	},
	getConsultationPrice: async (priceListName: string) => {
		const { data } = await monoApi.get<{
			price: number;
			duration: number;
		}>(`/api/v1/payout/price?priceListName=${priceListName}`);
		return data;
	},
	getVideoToken: async (consultationId?: number) => {
		const { data } = await monoApi.get<{ token: string }>(
			`/api/v1/consultations/video-token/${consultationId}`
		);
		return data;
	},
	getPrescriptions: async () => {
		const { data } = await monoApi.get<IPrescriptionResponse[]>(
			`/api/v1/consultations/prescriptions/patient/${userId}`
		);
		return data;
	},

	getPrescription: async (prescriptionId: number) => {
		const { data } = await monoApi.get<IPrescriptionResponse>(
			`/api/v1/consultations/prescription/${prescriptionId}`
		);

		return data as IPrescriptionResponse;
	},
	getPrescriptionsByConsultation: async (consultationId?: number) => {
		const { data } = await monoApi.get<IPrescriptionResponse[]>(
			`/api/v1/consultations/summary/prescription/${consultationId}?patientId=${userId}`
		);
		return data;
	},
	getVitals: async () => {
		const { data } = await monoApi.get<IVitalsResponse>(
			`/api/v1/patients/vitals/${userId}`
		);
		return data;
	},
	getChildVitals: async (childId: number) => {
		const { data } = await monoApi.get<IVitalsResponse>(
			`/api/v1/child/vitals/${userId}/${childId}`
		);
		return data;
	},
	updateVitalDetails: async (payload: any) => {
		// TODO: add type
		const { data } = await monoApi.post<IUpdateVitalDetailsResponse>(
			`/api/v1/vitals/create/${userId}`,
			payload
		);
		return data;
	},
	getMessages: async () => {
		const { data } = await monoApi.get<IMessageResponse[]>(
			`/api/v1/messages/user?userId=${userId}`
		);
		return data;
	},
	getMessage: async (messageId?: number) => {
		const { data } = await monoApi.get<IMessageResponse>(
			`/api/v1/messages/message/${messageId}`
		);
		return data;
	},
	getIsPaid: async (consultationId?: number) => {
		const { data } = await monoApi.get<IsPaid>(
			`/api/v1/consultations/followup/is-paid/${consultationId}`
		);
		return data;
	},
	verifyPayment: async (payload: IVerifyPaymentRequest) => {
		const { data } = await monoApi.post<IVerifyPaymentResponse>(
			'/api/v1/consultations/verify-payment',
			payload
		);
		return data;
	},
	createFeedback: async (payload: ICreateFeedbackRequest) => {
		const { data } = await monoApi.put<ICreateFeedbackResponse>(
			`/api/v1/consultations/rate?consultationId=${payload.consultationId}&rating=${payload.rating}`
		);
		return data;
	},
	createInquiry: async (payload: ICreateInquiryRequest) => {
		const { data } = await monoApi.post<ICreateInquiryResponse>(
			'/api/v1/inquiries/create/',
			payload
		);
		return data;
	},
	getSummaries: async () => {
		const { data } = await monoApi.get<ISummaryResponse[]>(
			`/api/v1/consultations/summary/all/${userId}`
		);
		return data;
	},

	getMedicalReport: async () => {
		const { data } = await monoApi.get<IMedicalReport[]>(
			`/api/v1/consultations/summary/medical/${userId}`
		);
		return data;
	},

	getConsultancyById: async (medicalId?: number) => {
		const { data } = await monoApi.get<IMedicalReport>(
			`/api/v1/consultations/summary/medical-record/${medicalId}`
		);
		return data;
	},

	getSummary: async (consultationId?: number) => {
		const { data } = await monoApi.get<ISummaryResponse>(
			`/api/v1/consultations/summary/${consultationId}`
		);
		return data;
	},
	getFollowUpSummary: async (consultationId?: number) => {
		const { data } = await monoApi.get<IFollowUpResponse>(
			`/api/v1/consultations/summary/followup/${consultationId}`
		);
		return data;
	},
	getLabResults: async (consultationId?: number) => {
		const { data } = await monoApi.get<ILabResultsResponse[]>(
			`/api/v1/consultations/summary/labrequest/${consultationId}`
		);
		return data;
	},
	getReceipt: async (consultationId?: number) => {
		const { data } = await monoApi.get<IReceiptResponse>(
			`/api/v1/consultations/summary/receipt/${consultationId}`
		);
		return data;
	},
	getFollowUp: async (consultationId?: number) => {
		// const { data } = await api.get<IFollowUpResponse>(
		// 	`/api/v1/consultations/summary/followup/${consultationId}`
		// );
		const { data } = await monoApi.get<IFollowUpResponse>(
			`/api/v1/consultations/summary/followup/${consultationId}?patientId=${userId}`
		);
		return data;
	},
	getInvitations: async () => {
		const { data } = await monoApi.get<IInvitation[]>(
			`/api/v1/invitations/users/${userId}`
		);
		return data;
	},
	createInvitation: async (payload: { name: string; email: string }) => {
		const { data } = await monoApi.post<IInvitation>(
			'/api/v1/invitations/create',
			payload
		);
		return data;
	},
	resendInvitation: async (invitationId: number) => {
		const { data } = await monoApi.post<IInvitation>(
			`/api/v1/invitations/resend/${invitationId}`
		);
		return data;
	},
	removeInvitation: async (invitationId: number) => {
		const { data } = await monoApi.delete<IInvitation>(
			`/api/v1/invitations/delete/${invitationId}`
		);
		return data;
	},
	retainConsultation: async (consultationId: number) => {
		const { data } = await monoApi.put<string>(
			`/api/v1/consultations/retain/${consultationId}`
		);
		return data;
	},
	getUserByEmail: async (email?: string) => {
		const { data } = await monoApi.get<IProfileResponse>(
			`/api/v1/users/getUserByEmail/${email}`
		);
		return data;
	},
	getConsultationReservated: async (followupID?: number) => {
		const { data } = await monoApi.get<IConsultationResponse>(
			`/api/v1/consultations/follow-up/${followupID}`
		);
		return data;
	},
	getSpeciality: async (query?: 'Patient' | 'Child') => {
		const { data } = await prodApi.get(
			`/api/Products/filter?clientType=${query}`
		);
		return data;
	},

	getAvailabilityCalendar: async (payload: IGetAvailabilityCalendar) => {
		console.log(payload);
		const { data } = await calApi.post(`/getavailability`, payload);
		return data;
	},
};
