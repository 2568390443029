// src/utils/imageLoader.ts

const continent = import.meta.env.VITE_PROVIDER_CONTINENT || 'eu'; // Default to 'eu' if not set

export const getImagePath = (imageName: string): string => {
  if (!imageName) {
    console.error('No image name provided');
    return '';
  }

  // Construct the image path
  try {
    const path = new URL(`../assets/images/menu/${continent}/${imageName}.svg`, import.meta.url).href;
    return path;
  } catch (error) {
    console.error('Error constructing image path:', error);
    return '';
  }
};
